<template>
  <section id="pageHero">

  </section>
</template>

<script setup lang="ts">
</script>

<style scoped lang="scss">
#pageHero {
  height: 30vh;
  background-image: url("@/assets/arti-torti-rybnik-header-tort-small.jpg");
  background-position: right center;
  background-repeat: no-repeat;
  background-size: cover;
}
@media (min-width: 800px) {
  #pageHero {
    background-image: url("@/assets/arti-torti-rybnik-header-tort-medium.jpg");
  }
}
</style>
