<template>
  <component :is="$route.meta.layout || 'DefaultLayout'">
    <router-view :key="$route.fullPath" />
  </component>
</template>

<style lang="scss">
@import '@/styles/_reset.scss';
@import '@/styles/_template.scss';
html {
  font-size: 62.5%;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  font-size: 2rem;
}
.page__container {
  margin: 5rem 1rem;
}
a {
  color: #42b983;
  text-decoration: none;
  a:hover {
    color: #42b983;
  }
}
p {
  margin: 0.2rem auto;
  line-height: 3rem;
  max-width: 70vw;
}
h1 {
  margin: 4rem 0;
  font-size: 2.7rem;
}
h2 {
  margin: 4rem 0;
  font-size: 2.5rem;
}
h3 {
  margin: 2rem 0;
  font-size: 2.2rem;
}
</style>
