<template>
  <footer class="pageFooter">
    <div class="pageFooter__section">
      <h3>Znajdziesz nas również</h3>
      <p><a class="anchor" href="https://www.facebook.com/artitortirybnik" target="_blank">facebook</a></p>
      <p><a class="anchor" href="https://www.instagram.com/artitortirybnik/" target="_blank">instagram</a></p>
    </div>
    <div class="pageFooter__section">
      <h3>Arti Torti</h3>
      <p>44-274 Rybnik Popielów</p>
      <p>ul. Konarskiego 77</p>
      <p>tel. 510-556-099</p>
    </div>
  </footer>
</template>

<script setup lang="ts">
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.pageFooter {
  background-color: #111;
  color: #ccc;
  padding: 2rem;
  min-height: 10vh;
  &__navigation {
    //display: flex;
    //flex-direction: row;
    a {
      padding: 0.4rem;
      font-weight: bold;
      text-decoration: none;
      color: #ccc;

      &.router-link-exact-active {
        color: #eee;
      }
    }
  }
  &__section {
    padding: 1rem;
  }
}
@media (min-width: 60em) {
  .page-footer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
  }
}
</style>
