<template>
  <header id="pageHeader" class="-desktop-sticky">
    <router-link to="/">
      <img id="pageHeader__logo" alt="Vue logo" src="../assets/arti-torti-cukiernia-rybnik-torty-logo-v2.jpg">
    </router-link>
    <nav id="pageHeader__navigation">
      <router-link to="/realizacje">Nasze realizacje</router-link>
      <router-link to="/zamowienia">Zamówienia</router-link>
      <router-link to="/kontakt">Kontakt</router-link>
    </nav>
  </header>
</template>

<script setup lang="ts">
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
#pageHeader {
  background-color: #fff;
  &.-desktop-sticky {
    @media (min-width: 40em) {
      position: sticky;
      top: 0;
    }
  }
  &__logo {
    max-width: min(100%, 748px);
  }
  &__navigation {
    padding: 30px;
    //display: flex;
    //flex-direction: row;
    a {
      font-size: 2.2rem;
      display: block;
      padding: 0.4rem;
      font-weight: bold;
      text-decoration: none;
      color: #2c3e50;

      &.router-link-exact-active {
        color: #42b983;
      }
    }
  }
}
</style>
