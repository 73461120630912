import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router';
import HomeView from '../views/HomeView.vue';
import GalleryView from '../views/GalleryView.vue';
import OrdersView from '../views/OrdersView.vue';
import OfferView from '../views/OfferView.vue';
import ContactView from '../views/ContactView.vue';

import NotFoundPage from '@/pages/NotFoundPage.vue';

declare module 'vue-router' {
    interface RouteMeta {
        // is optional
        isAdmin?: boolean;
        // must be declared by every route
        requiresAuth: boolean;
        layout: string;
    }
}

const routes: Array<RouteRecordRaw> = ([] as RouteRecordRaw[]).concat(
    [
        {
            path: '/',
            name: 'home',
            component: HomeView
        },
        {
            path: '/realizacje',
            name: 'gallery',
            component: GalleryView
        },
        {
            path: '/zamowienia',
            name: 'orders',
            component: OrdersView
        },
        {
            path: '/oferta',
            name: 'offer',
            component: OfferView
        },
        {
            path: '/kontakt',
            name: 'contact',
            component: ContactView
        },
        {
            path: '/about',
            name: 'about',
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
        },
        {
            path: '/:pathMatch(.*)',
            components: {
                default: NotFoundPage,
                // footer: ContactUs,
            },
            meta: {
                requiresAuth: false,
                layout: 'ErrorLayout',
            },
        },
    ],
);

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

// router.beforeEach((to, from, next) => {
//   if (!['/login', '/password-recovery', '/register'].includes(to.path) && !authStore.getters.isAuthenticated) {
//     next({ path: '/login' });
//   } else {
//     next();
//   }
// });

export default router;
