import { createApp } from 'vue';
import App from './App.vue';
import ElementPlus from 'element-plus';
import { createPinia } from 'pinia';
import 'element-plus/dist/index.css';
import router from './router';

import DefaultLayout from '@/layouts/DefaultLayout.vue';
import ErrorLayout from '@/layouts/ErrorLayout.vue';
import UnauthorizedLayout from '@/layouts/UnauthorizedLayout.vue';

const app = createApp(App);
app.use(router);
app.use(ElementPlus);
app.use(createPinia());
app.component("DefaultLayout", DefaultLayout);
app.component("ErrorLayout", ErrorLayout);
app.component("UnauthorizedLayout", UnauthorizedLayout);
app.mount("#app");
